// VE Basic
/* global session, auth */
const del = ','
const newline = '\n'
const nl = (typeof module === 'undefined') ? '' : newline // add newline in node/lambda
const debugData = false

const addToLog = (logMsg, debug = debugData) => { // debug: 'true', 'false', 'booDebug', 'booDebugAws', etc
  // let tmpDebug = (typeof booDebug === 'undefined') ? true : booDebug // global booDebug is undefined in node/lambda
  // const shortDebug = (String(debug)).substr(0, 20)
  // tmpDebug = (typeof debug === 'undefined') ? tmpDebug : eval(shortDebug)
  if (debug) console.log(logMsg + nl)
  // todo: add code to 'log' to file?
}

const hasProp = (thisObj, key) => {
  if (!thisObj) return false
  return Object.prototype.hasOwnProperty.call(thisObj, key)
}

const initDebug = (localDebug, globalDebug) => { // todo: remove
  return (typeof localDebug === 'undefined') ? globalDebug : localDebug
}

const setCookie = (name, value, days) => {
  let expires = ''
  if (days) {
    const date = new Date()
    date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000))
    expires = '; expires=' + date.toUTCString()
  }
  document.cookie = name + '=' + (value || '') + expires + '; path=/'
  addToLog('veb:setCookie - name: ' + name + ', document.cookie: ' + document.cookie, debugData)
}

const getCookie = (name) => {
  const nameEQ = name + '='
  const ca = document.cookie.split(';')
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i]
    while (c.charAt(0) === ' ') c = c.substring(1, c.length)
    if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length)
  }
  return null
}

const eraseCookie = (name) => {
  document.cookie = name + '=; Max-Age=-99999999;'
}

const setVeEnv = (veEnv, debug = debugData) => {
  addToLog('setVeEnv:veEnv: ' + veEnv, debug)
  setCookie('veEnv', veEnv)
}

const migrateVeEnv = (thisEnv, debug = debugData) => {
  addToLog('migrateVeEnv:thisEnv: ' + thisEnv, debug)
  if (!thisEnv) return
  if (thisEnv === 'cddev') return 've-cd-dev'
  if (thisEnv === 'dev' || thisEnv === 'prod') return 've' + thisEnv
  return thisEnv.substring(0, 2) + '-' + thisEnv.substring(2)
}

const getVeEnv = (debug = debugData) => {
  let veEnv = getCookie('veEnv')
  // migrate veEnv to include hyphen // todo: remove after 'migration'
  if (veEnv) {
    if (['dev', 'prod', 'cddev', 'tptest'].includes(veEnv)) {
      veEnv = migrateVeEnv(veEnv, debug)
      setVeEnv(veEnv, debug)
    }
  }
  addToLog('veb:getVeEnv:veEnv: ' + veEnv, debug)
  return veEnv
}

const toProperCase = (s) => {
  return s.toLowerCase().replace(/^(.)|\s(.)/g, function ($1) { return $1.toUpperCase() })
}

const toLowerCase = (s) => {
  return (typeof s === 'undefined') ? s : s.toLowerCase()
}

const toUpperCase = (s) => {
  return (typeof s === 'undefined') ? s : s.toUpperCase()
}

const toFirstUpperCase = (s) => {
  const first = s.substr(0, 1).toUpperCase()
  return first + s.substring(1)
}

const toFirstLowerCase = (s) => {
  const first = s.substr(0, 1).toLowerCase()
  return first + s.substring(1)
}

const shortISOToLongISO = (shortISO) => {
  // Assume short ISO does not support milliseconds
  // Short: 19780625T010203Z or 19780625T010203+10:00
  // Long: 1983-08-12T02:04:06Z or 1983-08-12T02:04:06-5:30
  const year = shortISO.substr(0, 4)
  const month = shortISO.substr(4, 2)
  const day = shortISO.substr(6, 2)
  if (shortISO.length === 8) {
    return year + '-' + month + '-' + day + 'T00:00:00Z'
  }
  const hours = shortISO.substr(9, 2)
  const minutes = shortISO.substr(11, 2)
  const seconds = shortISO.substr(13, 2)
  const offset = shortISO.substr(15, 6)
  return year + '-' + month + '-' + day + 'T' + hours + ':' + minutes + ':' + seconds + offset
}

const jsDateToLocalISO = (date, offset) => {
  const year = ('000' + date.getFullYear()).slice(-4)
  const month = ('0' + (date.getMonth() + 1)).slice(-2)
  const day = ('0' + date.getDate()).slice(-2)
  const hours = ('0' + date.getHours()).slice(-2)
  const minutes = ('0' + date.getMinutes()).slice(-2)
  const seconds = ('0' + date.getSeconds()).slice(-2)
  return year + '-' + month + '-' + day + 'T' + hours + ':' + minutes + ':' + seconds + offset
}

const decimalToTimezoneOffsetString = (input) => {
  const number = Math.abs(input)
  const min = ('0' + parseInt((number - parseInt(number, 10)) * 60, 10)).slice(-2)
  const hour = ('0' + parseInt(number, 10)).slice(-2)
  const sign = input >= 0 ? '+' : '-'
  return sign + hour + ':' + min
}

const utcISOToJSDate = (utcISO) => {
  const ms = parseInt(utcISO.substr(20, 3), 10) || 0
  const date = new Date(Date.UTC(
    parseInt(utcISO.substr(0, 4), 10),
    parseInt(utcISO.substr(5, 2), 10) - 1,
    parseInt(utcISO.substr(8, 2), 10),
    parseInt(utcISO.substr(11, 2), 10),
    parseInt(utcISO.substr(14, 2), 10),
    parseInt(utcISO.substr(17, 2), 10),
    ms
  ))
  return date
}

const utcISOToLocalISO = (utcISO, timezone) => {
  const date = utcISOToJSDate(utcISO)
  const locale = 'en-US'
  const utcDate = new Date(date.toLocaleString(locale, { timeZone: 'UTC' }))
  const localDate = new Date(date.toLocaleString(locale, { timeZone: timezone }))
  const offset = parseInt((localDate.getTime() - utcDate.getTime()) / 1000, 10) / 60 / 60
  const offsetString = decimalToTimezoneOffsetString(offset)
  return jsDateToLocalISO(localDate, offsetString)
}

const longISOToShortISO = (longISO) => {
  // Assume short ISO does not support milliseconds
  // Short: 19780625T010203Z or 19780625T010203+10:00
  // Long: 1983-08-12T02:04:06Z or 1983-08-12T02:04:06-5:30
  const year = longISO.substr(0, 4)
  const month = longISO.substr(5, 2)
  const day = longISO.substr(8, 2)
  if (longISO.length === 10) {
    return year + month + day + 'T000000Z'
  }
  const hours = longISO.substr(11, 2)
  const minutes = longISO.substr(14, 2)
  const seconds = longISO.substr(17, 2)
  let offset = longISO.substr(19, 6)
  if (longISO.includes('.')) {
    offset = longISO.substr(23, 6)
  }
  return year + month + day + 'T' + hours + minutes + seconds + offset
}

const shortISOUtcToLocal = (shortUTCISO, timezone) => {
  const longUTCISO = shortISOToLongISO(shortUTCISO)
  const localISO = utcISOToLocalISO(longUTCISO, timezone)
  return longISOToShortISO(localISO)
}

const getTimezoneFromMicrogridId = (microgridId) => {
  // addToLog('getTimezoneFromMicrogridId - microgridId: ' + microgridId, booDebugData);
  const timezones = { IN: 'Asia/Kolkata', AU: 'Australia/Sydney', BM: 'Atlantic/Bermuda', KH: 'Asia/Phnom_Penh' }
  timezones['AU-2'] = 'Australia/Sydney'
  timezones['AU-7'] = 'Australia/Sydney'
  timezones['AU-4'] = 'Australia/Brisbane'
  timezones['AU-5'] = 'Australia/Adelaide'
  timezones['AU-6'] = 'Australia/Perth'
  timezones['AU-0'] = 'Australia/Darwin'
  let timezone = ''
  try { // get 'country' default timezone
    timezone = timezones[microgridId.substr(0, 2)]
  } catch (e) {
    // addToLog('getTimezoneFromMicrogridId - error: ' + e, booDebugData);
  }
  try { // get 'country + start of postcode' timezone
    const tz = timezones[microgridId.substr(0, 4)]
    timezone = (typeof tz === 'undefined') ? timezone : tz
  } catch (e) {
    // addToLog('getTimezoneFromMicrogridId - error: ' + e, true);
  }
  return timezone
}

const getTimezoneFromContext = (selectedContext) => { // todo: ensure selectedContext is provided
  // selectedContext = (typeof selectedContext === 'undefined') ? ve.ctx.selectedContext : selectedContext
  const microgridIdStartsWith = toUpperCase(selectedContext.substr(0, 2)) // todo: get microgridId from context if present
  return getTimezoneFromMicrogridId(microgridIdStartsWith)
}

const getTimezoneOffset = (shortIso, microgridId) => {
  const timezone = getTimezoneFromMicrogridId(microgridId)
  const offset = shortISOUtcToLocal(shortIso, timezone).substr(15)
  return offset
}

const getTimezoneOffsetInSeconds = (shortIso, microgridId) => {
  const offset = getTimezoneOffset(shortIso, microgridId) // +08:00, -04:00
  const [hour, minute] = offset.split(':').map(t => parseInt(t, 10))
  return 60 * ((hour * 60) + minute)
}

const getSecondsSinceMidnight = (d) => {
  const ssm = d.getUTCHours() * 3600 + d.getUTCMinutes() * 60 + d.getUTCSeconds()
  return ssm
}

const formatTimeStamp = (longIsoTimeStamp) => { // convert long ISO to short ISO format
  let ts = longIsoTimeStamp // ISO 8601 YYYY-MM-DDTHH:mm:ss.sssZ
  ts = ts.replace(/-/g, '')
  ts = ts.replace(/:/g, '')
  if (ts.length === 8) ts += 'T000000' // add time component if date only
  ts = ts.substr(0, 15) + 'Z'
  return ts
}

const getTimeStamp = () => { // get a new date in short ISO format (locale machine date time)
  const date = new Date()
  const longIsoTimeStamp = date.toISOString() // ISO 8601 YYYY-MM-DDTHH:mm:ss.sssZ
  return formatTimeStamp(longIsoTimeStamp)
}

const getMicrogridTimeStamp = (microgridId) => {
  const shortIso = getTimeStamp()
  const timezone = getTimezoneFromMicrogridId(microgridId)
  return shortISOUtcToLocal(shortIso, timezone)
}

const getLongISOTimeStamp = (includeFraction) => { // get a new date in short ISO format (locale machine date time)
  let longIsoTimeStamp = new Date().toISOString() // ISO 8601 YYYY-MM-DDTHH:mm:ss.sssZ
  if (typeof includeFraction === 'undefined' || !includeFraction) longIsoTimeStamp = longIsoTimeStamp.substr(0, 19) + 'Z'
  return longIsoTimeStamp
}

const isShortISO = (isoDate) => {
  const hasThreeHyphen = (isoDate.split('-').length === 3)
  const hasThreeColon = (isoDate.split(':').length === 3)
  return !(hasThreeHyphen && hasThreeColon)
}

const toLongISO = (shortIso) => {
  const longIso = isShortISO(shortIso) ? shortISOToLongISO(shortIso) : shortIso
  return longIso
}

const shortISOToDate = (shortIsoTimestamp) => {
  // ISO 8601 YYYY-MM-DDTHH:mm:ss.sssZ
  // short ISO 20181016T050430Z
  let ts = shortIsoTimestamp
  if (ts) {
    if (ts.length === 8) ts += 'T000000Z'
    if (ts.length === 9) ts += '000000Z'
    const offset = (ts.length > 15) ? ts.substr(15) : 'Z'
    const strYYYY = ts.substr(0, 4)
    const strMM = ts.substr(4, 2)
    const strDD = ts.substr(6, 2)
    const strHours = ts.substr(9, 2)
    const strMinutes = ts.substr(11, 2)
    const strSeconds = ts.substr(13, 2)
    const strDate = strYYYY + '-' + strMM + '-' + strDD + 'T' + strHours + ':' + strMinutes + ':' + strSeconds + offset // 'Z';
    return (new Date(strDate))
  }
}

const dateToShortISO = (dt) => {
  return longISOToShortISO(dt.toISOString())
}

const shortISOToUnixTimestamp = (shortIsoDate) => { // convert to Unix timestamp (in seconds)
  return Date.parse(shortISOToDate(shortIsoDate)) / 1000
}

const longISOToUnixTimestamp = (longIsoDate) => { // convert to Unix timestamp (in seconds)
  return shortISOToUnixTimestamp(longISOToShortISO(longIsoDate))
}

const unixTimestampToShortISO = (unixTimestamp, timezone) => { // unixTimestamp in seconds
  timezone = (typeof timezone === 'undefined') ? 'utc' : timezone
  const isoDate = new Date(unixTimestamp * 1000).toISOString()
  const longIsoLocalDate = utcISOToLocalISO(isoDate, timezone)
  let shortISOLocalDate = longISOToShortISO(longIsoLocalDate)
  shortISOLocalDate = shortISOLocalDate.replace(/\+00:00/, 'Z')
  return shortISOLocalDate
}

/*
 * Adds time to a date. Modelled after MySQL DATE_ADD function.
 * Example: dateAdd(new Date(), 'minute', 30)  //returns 30 minutes from now.
 * https://stackoverflow.com/a/1214753/18511
 *
 * @param date  Date to start with
 * @param interval  One of: year, quarter, month, week, day, hour, minute, second
 * @param units  Number of units of the given interval to add.
 */
const dateAdd = (date, interval, units) => {
  if (units === 0) return date
  let ret = new Date(date) // don't change original date
  const checkRollover = function () { if (ret.getDate() !== date.getDate()) ret.setDate(0) }
  switch (interval.toLowerCase()) {
    case 'year' : ret.setFullYear(ret.getFullYear() + units); checkRollover(); break
    case 'quarter': ret.setMonth(ret.getMonth() + 3 * units); checkRollover(); break
    case 'month' : ret.setMonth(ret.getMonth() + units); checkRollover(); break
    case 'week' : ret.setDate(ret.getDate() + 7 * units); break
    case 'day' : ret.setDate(ret.getDate() + units); break
    case 'hour' : ret.setTime(ret.getTime() + units * 3600000); break
    case 'minute' : ret.setTime(ret.getTime() + units * 60000); break
    case 'second' : ret.setTime(ret.getTime() + units * 1000); break
    case 'millisecond' : ret.setTime(ret.getTime() + units); break
    default : ret = undefined; break
  }
  return ret
}

const dateAddShortISO = (dateShortIso, interval, units) => { // adds units intervals but returns shortISO in UTC
  dateShortIso += (dateShortIso.length === 8) ? 'T000000Z' : ''
  const dateStart = shortISOToDate(dateShortIso)
  const dateEnd = dateAdd(dateStart, interval, units)
  return longISOToShortISO(dateEnd.toISOString())
}

const dateAddShortISOLocal = (dateShortIso, interval, units, timezone) => {
  // adds units intervals but returns shortISO in same timezone as dateShortIso
  const dateStart = shortISOToDate(dateShortIso)
  const dateEnd = dateAdd(dateStart, interval, units)
  const dateEndLongISOLocal = utcISOToLocalISO(dateEnd.toISOString(), timezone)
  const dateShortISOLocal = longISOToShortISO(dateEndLongISOLocal)
  return dateShortISOLocal
}

const shortISOAddSeconds = (shortIso, addSeconds, timezone) => {
  const unixTS = shortISOToUnixTimestamp(shortIso) + addSeconds
  return unixTimestampToShortISO(unixTS, timezone)
}

const longISOAddSeconds = (longIso, addSeconds, timezone) => {
  const shortIso = longISOToShortISO(longIso)
  const newShortIso = shortISOAddSeconds(shortIso, addSeconds, timezone)
  const newLongIso = shortISOToLongISO(newShortIso)
  return newLongIso
}
const jsDateToLocalShortISO = (date, offset) => {
  const localShortIso = jsDateToLocalISO(date, offset)
  const localShortIsoWithoutOffset = localShortIso.substr(0, localShortIso.length - offset.length)
  return localShortIsoWithoutOffset.replace(/-/g, '').replace(/:/g, '') + offset
}

const calcSecondsBetweenTwoTS = (from, to) => {
  return (shortISOToDate(to) - shortISOToDate(from)) / 1000
}

const calcReadsBetweenTwoTS = (from, to, interval) => { // number of 15-second periods between two timestamps
  interval = (typeof interval === 'undefined') ? 15 : interval
  const deltaInSeconds = calcSecondsBetweenTwoTS(from, to)
  const readsBetweenTwoTS = parseInt(deltaInSeconds / interval, 10)
  return readsBetweenTwoTS
}

const getDviAccessToken = (debug = debugData) => {
  return window.sessionStorage.getItem('dviAccessToken')
}

const getDviRefreshToken = (debug = debugData) => {
  return window.sessionStorage.getItem('dviRefreshToken')
}

const setDviAccessToken = (dviAccessToken, debug = debugData) => {
  window.sessionStorage.setItem('dviAccessToken', dviAccessToken)
  session.accessToken = dviAccessToken
}

const setDviRefreshToken = (dviRefreshToken, debug = debugData) => {
  window.sessionStorage.setItem('dviRefreshToken', dviRefreshToken)
}

const getAccessToken = (debug = debugData) => {
  addToLog('veb:getAccessToken:session: ' + JSON.stringify(session), debug)
  if (!session) return null // redirectToLogin ??
  if (session.getAccessToken) return session.getAccessToken().getJwtToken()
  if (session.accessToken) return session.accessToken
}

const parseJwt = (token, debug = debugData) => {
  const base64Url = token.split('.')[1]
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
  const jsonPayload = decodeURIComponent(window.atob(base64).split('').map(c => {
    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
  }).join(''))
  return JSON.parse(jsonPayload)
}

const decodeJwt = (token, debug = debugData) => {
  try {
    // return JSON.parse(atob(token.split('.')[1]))
    return parseJwt(token, debug)
  } catch (e) {
    return null
  }
}

const isTokenExpired = (token, debug = debugData) => {
  if (!token) return true
  const decodedToken = decodeJwt(token, debug)
  if (decodedToken && hasProp(decodedToken, 'exp')) {
    const exp = decodedToken.exp
    const nowTS = shortISOToUnixTimestamp(getTimeStamp())
    // isTokenExpired:exp: 1626230637490, nowTS: 1626239027
    const convertToSeconds = (unixTimestampToShortISO(exp).substr(0, 4) === getTimeStamp().substr(0, 4)) ? 1 : 1000
    addToLog('isTokenExpired:exp: ' + exp + ', nowTS: ' + nowTS + ', convertToSeconds: ' + convertToSeconds, debug)
    return (exp / convertToSeconds) < nowTS
  }
}

const isAccessTokenExpired = (debug = debugData) => {
  const token = getAccessToken(debug)
  return isTokenExpired(token, debug)
}

const initVeEnv = (thisEnv, vecfg, debug = debugData) => { // set ve environment related vars
  if (thisEnv) setVeEnv(thisEnv, debug)
  if (!thisEnv) thisEnv = getVeEnv(debug)
  addToLog('veb:initVeEnv:thisEnv: ' + thisEnv, debug)
  return true
}

const initVeEnvDvi = (thisEnv, vecfg, debug = debugData) => { // set ve environment related vars
  if (thisEnv) setVeEnv(thisEnv, debug)
  if (!thisEnv) thisEnv = getVeEnv(debug)
  addToLog('veb:initVeEnv:thisEnv: ' + thisEnv, debug)
  return true
}

const initPageDvi = (vecfg, debug = debugData) => {
  const veEnv = getVeEnv(debug)
  const currentUrl = window.location.href
  addToLog('initPageDvi:veEnv: ' + veEnv + ', currentUrl: ' + currentUrl, debug)
  if (currentUrl.indexOf('.html?jwt=') > -1) {
    initVeEnvDvi(veEnv, vecfg, debug)
    const jwt = currentUrl.split('jwt=')[1].split('#')[0]
    addToLog('initPageDvi:jwt: ' + jwt, true)
    setDviAccessToken(jwt, debug)
    addToLog('initPageDvi:jwt: ' + jwt + ', currentUrl: ' + currentUrl, debug)
  }
}

const getDatalakeBucketName = (debug = debugData) => {
  const veEnv = getVeEnv(debug)
  let bucketName = veEnv + '-datalake'
  if (veEnv === 'vedev') bucketName = 've-datalake'
  if (veEnv === 'veprod') bucketName = 've-prod-datalake'
  return bucketName
}

const redirectToLogin = (debug = debugData) => {
  if (auth) {
    auth.signOut()
  } else {
    // redirect to url without '#id_token=...' or '?code=
    let url = '' + window.location
    const splitUrl = url.split('.html')
    url = splitUrl[0] + '.html'
    window.location = url
  }
}

const parseBoolean = (value, debug = debugData) => {
  if (typeof value === 'boolean') return value
  if (typeof value === 'string') {
    value = value.trim()
    if (value === '1') return true
    if (value.toLowerCase() === 'true') return true
    if (value.toLowerCase() === 'false') return false
  }
  if (typeof value === 'number') return (value === 1)
  return false
}

const isMoustacheExpression = (paramValue) => { // moustache
  if (typeof paramValue !== 'string') return false
  return (paramValue.includes('{{') && paramValue.includes('}}'))
}

const isMathjsExpression = (paramValue) => { // mathjs.org
  if (typeof paramValue !== 'string') return false
  const expressionOperators = [' - ', ' + ', ' * ', ' / ', ' > ', ' < ', ' or ', ' and ']
  for (const eo of expressionOperators) {
    if (paramValue.includes(eo)) return true
  }
  return false
}

const isLogicalExpression = (paramValue) => { // logical JS
  if (typeof paramValue !== 'string') return false
  const expressionOperators = [' || ', ' && ']
  for (const eo of expressionOperators) {
    if (paramValue.includes(eo)) return true
  }
  return false
}

const evaluateLogicalOr = (paramValue) => {
  const orOperator = ' || '
  const orValues = paramValue.split(orOperator)
  let returnValue = paramValue
  for (const orValue of orValues) {
    if (orValue.trim() !== '') {
      returnValue = orValue.trim() // first non-blank value
      break
    }
  }
  return returnValue
}

const evaluateLogicalAnd = (paramValue) => {
  const andOperator = ' && '
  const andValues = paramValue.split(andOperator)
  let returnValue = paramValue
  for (const andValue of andValues) {
    returnValue = andValue.trim() // last non-blank value
    if (andValue.trim() === '') break
  }
  return returnValue
}

const evaluateLogicalExpression = (paramValue) => { // e.g. 'min' || 'max' ... return 'min'
  const orOperator = paramValue.includes(' || ') ? ' || ' : null
  let andOperator = paramValue.includes(' && ') ? ' && ' : null
  if (!(orOperator || andOperator)) return paramValue
  let returnValue = paramValue
  if (orOperator) {
    returnValue = evaluateLogicalOr(paramValue)
    andOperator = returnValue.includes(' && ') ? ' && ' : null
  }
  if (andOperator) returnValue = evaluateLogicalAnd(returnValue)
  return returnValue
}

const isApiExpression = (paramValue) => { // todo: handle Energy, Ledger api
  if (typeof paramValue !== 'string') return false
  const apiKeys = ['Dpe.', 'Energy.', 'Ledger.']
  for (const apiKey of apiKeys) {
    if (paramValue.startsWith(apiKey)) return true
  }
  return false
}

const getDynamicPriceJson = (dynamicPrice, durationInSeconds) => {
  // Dynamic price: {start: 20181016T050430Z, finish: 20181016T051415Z, price: [1.0, 1.1...], count: [10,5...]}
  const intervalInSeconds = 15
  durationInSeconds = Math.ceil(durationInSeconds / intervalInSeconds) * intervalInSeconds // round up to next 15s
  const count = durationInSeconds / intervalInSeconds
  let dtStart = new Date()
  let start = formatTimeStamp(dtStart.toISOString())
  // get seconds from midnight for the start of next interval
  const secondsSinceMidnight = getSecondsSinceMidnight(dtStart)
  const intStartOfNextInterval = secondsSinceMidnight + intervalInSeconds - (secondsSinceMidnight % intervalInSeconds)
  if (intStartOfNextInterval > secondsSinceMidnight) {
    start = formatTimeStamp(dtStart.toISOString())
    dtStart = dateAdd(dtStart.setUTCHours(0, 0, 0, 0), 'second', intStartOfNextInterval)
    start = formatTimeStamp(dtStart.toISOString())
  }
  const finish = shortISOAddSeconds(start, durationInSeconds)
  const strDPJson = { start: start, finish: finish, price: [dynamicPrice], count: [count] }
  return strDPJson
}

const getSettingProfile = (settingName, settingValue, durationInSeconds) => {
  // { start: 20181016T050430Z, finish: 20181016T051415Z, <settingName>: [<settingValue>], count: [123]}
  const intervalInSeconds = 15
  durationInSeconds = Math.ceil(durationInSeconds / intervalInSeconds) * intervalInSeconds // round up to next 15s
  const count = durationInSeconds / intervalInSeconds
  let dtStart = new Date()
  let start = formatTimeStamp(dtStart.toISOString())
  // get seconds from midnight for the start of next interval
  const secondsSinceMidnight = getSecondsSinceMidnight(dtStart)
  const intStartOfNextInterval = secondsSinceMidnight + intervalInSeconds - (secondsSinceMidnight % intervalInSeconds)
  if (intStartOfNextInterval > secondsSinceMidnight) {
    start = formatTimeStamp(dtStart.toISOString())
    dtStart = dateAdd(dtStart.setUTCHours(0, 0, 0, 0), 'second', intStartOfNextInterval)
    start = formatTimeStamp(dtStart.toISOString())
  }
  const finish = shortISOAddSeconds(start, durationInSeconds)
  const settingProfile = { start: start, finish: finish, count: [count] }
  settingProfile[settingName] = [settingValue]
  return settingProfile
}

const getDynamicPriceProfile = (dynamicPrice, durationInSeconds) => {
  return getSettingProfile('price', dynamicPrice, durationInSeconds)
}

const getCurtailmentProfile = (pSetPoint, durationInSeconds) => {
  return getSettingProfile('pSetPoint', pSetPoint, durationInSeconds)
}

const getPrice = (dtInterval, priceProfile) => {
  /*
  jsnDAP: {"start":"20190407T023035Z","finish":"20190408T023035Z","price":[4.8,4.7,4.6,4.5,4.3,4.2,4.1,3.9,3.8,3.7,3.6,3.5,3.4,3.3,3.3,3.2,3.2,3.2,3.2,3.2,3.2,3.2,3.2,3.2,3.2,3.3,3.3,3.3,3.3,3.3,3.3,3.3,3.3,3.2,3.2,3.2,3.2,3.2,3.2,3.1,3.1,3.1,3.1,3.1,3.1,3.1,3,3,3,3,3,2.9,2.9,2.9,2.8,2.8,2.7,2.6,2.6,2.5,2.4,2.3,2.3,2.2,2.1,2,2,1.9,1.9,1.8,1.8,1.8,1.8,1.8,1.8,1.8,1.9,1.9,1.9,2,2,2,2.1,2.1,2.1,2.2,2.2,2.2,2.3,2.3,2.3,2.4,2.4,2.5,2.5,2.6,2.7,2.8,2.9,3,3.1,3.3,3.4,3.6,3.7,3.9,4,4.2,4.3,4.5,4.6,4.7,4.8,4.8,4.9,4.9,4.9,4.8,4.8,4.7],"count":[5,5,5,5,5,5,5,5,5,5,5,5,5,5,5,5,5,5,5,5,5,5,5,5,5,5,5,5,5,5,5,5,5,5,5,5,5,5,5,5,5,5,5,5,5,5,5,5,5,5,5,5,5,5,5,5,5,5,5,5,5,5,5,5,5,5,5,5,5,5,5,5,5,5,5,5,5,5,5,5,5,5,5,5,5,5,5,5,5,5,5,5,5,5,5,5,5,5,5,5,5,5,5,5,5,5,5,5,5,5,5,5,5,5,5,5,5,5,5,5]}
  */
  if (typeof priceProfile === 'undefined') return null
  if (typeof priceProfile === 'string') priceProfile = JSON.parse(priceProfile)
  if (!priceProfile) return null
  // cater for missing start & finish in default day ahead price
  if (!hasProp(priceProfile, 'start') || !hasProp(priceProfile, 'finish')) {
    const dtNow = new Date()
    let strNow = dtNow.toISOString()
    strNow = strNow.replace(/-/g, '')
    // addToLog("strNow: " + strNow);
    if (!hasProp(priceProfile, 'start')) priceProfile.start = strNow.substr(0, 8) + 'T000000Z'
    if (!hasProp(priceProfile, 'finish')) priceProfile.finish = strNow.substr(0, 8) + 'T235959Z'
  }
  const intIntervalInSeconds = (typeof priceProfile.intervalInSeconds !== 'undefined') ? priceProfile.intervalInSeconds : 15 // use hardcoded if not present
  const dtStart = shortISOToDate(priceProfile.start)
  const dtFinish = shortISOToDate(priceProfile.finish)
  const intStartOffsetInSeconds = 10 // accept near future start timestamps
  if ((dtInterval + (intStartOffsetInSeconds * 1000)) < dtStart || dtInterval > dtFinish) return null // interval date is not within start & finish
  // addToLog('getPrice - dtInterval: ' + dtInterval + ', dtStart: ' + dtStart + ', dtFinish: ' + dtFinish);
  const secondsSinceStartOfProfile = (dtInterval - dtStart) / 1000
  // addToLog("secondsSinceStartOfProfile: " + secondsSinceStartOfProfile);
  const targetInterval = secondsSinceStartOfProfile / intIntervalInSeconds // priceProfile.intervalInSeconds;
  // addToLog("targetInterval: " + targetInterval);
  // Traverse price & count array to get the current price
  let intervalCount = 0
  for (const i in priceProfile.count) {
    const count = priceProfile.count[i]
    intervalCount += count
    if (targetInterval < intervalCount) {
      // addToLog('getPrice - intervalCount: ' + intervalCount + ', .start: ' + priceProfile.start + ', .finish: ' + priceProfile.finish + ', returning ' + priceProfile.price[i]);
      return priceProfile.price[i]
    }
  }
}

const getSettingFromProfile = (dtInterval, profile, settingName) => {
  // jsnDAP: {"start":"20190407T023035Z","finish":"20190408T023035Z","pSetPoint":[4.8,4.7],"count":[5,5]}
  if (typeof profile === 'undefined') return null
  if (typeof profile === 'string') profile = JSON.parse(profile)
  if (typeof dtInterval === 'string') dtInterval = shortISOToDate(dtInterval)
  if (!profile) return null
  // cater for missing start & finish in default day ahead price
  if (!hasProp(profile, 'start') || !hasProp(profile, 'finish')) {
    const dtNow = new Date()
    let strNow = dtNow.toISOString()
    strNow = strNow.replace(/-/g, '')
    // addToLog("strNow: " + strNow);
    if (!hasProp(profile, 'start')) profile.start = strNow.substr(0, 8) + 'T000000Z'
    if (!hasProp(profile, 'finish')) profile.finish = strNow.substr(0, 8) + 'T235959Z'
  }
  const intIntervalInSeconds = (typeof profile.intervalInSeconds !== 'undefined') ? profile.intervalInSeconds : 15 // use hardcoded if not present
  const dtStart = shortISOToDate(profile.start)
  const dtFinish = shortISOToDate(profile.finish)
  const intStartOffsetInSeconds = 10 // accept near future start timestamps
  if ((dtInterval + (intStartOffsetInSeconds * 1000)) < dtStart || dtInterval > dtFinish) return null // interval date is not within start & finish
  // addToLog('getPrice - dtInterval: ' + dtInterval + ', dtStart: ' + dtStart + ', dtFinish: ' + dtFinish);
  const secondsSinceStartOfProfile = (dtInterval - dtStart) / 1000
  // addToLog("secondsSinceStartOfProfile: " + secondsSinceStartOfProfile);
  const targetInterval = secondsSinceStartOfProfile / intIntervalInSeconds // priceProfile.intervalInSeconds;
  // addToLog("targetInterval: " + targetInterval);
  // Traverse settingName & count array to get the current settingValue
  let intervalCount = 0
  for (const i in profile.count) {
    const count = profile.count[i]
    intervalCount += count
    if (targetInterval < intervalCount) {
      // addToLog('getPrice - intervalCount: ' + intervalCount + ', .start: ' + priceProfile.start + ', .finish: ' + priceProfile.finish + ', returning ' + priceProfile.price[i]);
      return profile[settingName][i]
    }
  }
}

const getPSetPoint = (dtInterval, curtailmentProfile) => {
  // jsnDAP: { "start":"20190407T023035Z","finish":"20190408T023035Z", "pSetPoint":[4.8,4.7], "count":[5,5] }
  return getSettingFromProfile(dtInterval, curtailmentProfile, 'pSetPoint')
}

const getCurtailmentPercentage = (dtInterval, curtailmentProfile) => {
  // jsnDAP: { "start":"20190407T023035Z","finish":"20190408T023035Z", "pSetPoint":[4.8,4.7], "count":[5,5] }
  return getSettingFromProfile(dtInterval, curtailmentProfile, 'curtailmentPercentage')
}

const isNumeric = (n) => {
  return !isNaN(parseFloat(n)) && isFinite(n)
}

const roundTo = (n, digits) => {
  if (!isNumeric(n)) return n
  n = parseFloat(n)
  let negative = false
  if (digits === undefined) {
    digits = 0
  }
  if (n < 0) {
    negative = true
    n = n * -1
  }
  const multiplicator = Math.pow(10, digits)
  n = parseFloat((n * multiplicator).toFixed(11))
  n = (Math.round(n) / multiplicator).toFixed(digits)
  if (negative) {
    n = (n * -1).toFixed(digits)
  }
  return parseFloat(n)
}

const roundToTwo = (num) => {
  return roundTo(num, 2)
}

const padTo = (n, digits) => {
  // pad number with 0's in 'missing' decimal places, e.g. n .. 0.43 with digits .. 5 should result in 0.43000
  // n is float or string and returns string
  n = '' + n
  const maxZeros = '0'.repeat(digits)
  if (n.includes('.')) {
    const nArray = n.split('.')
    return nArray[0] + '.' + (nArray[1] + maxZeros).substr(0, digits)
  } else {
    return n + '.' + maxZeros
  }
}

const padToTwo = (n) => {
  return padTo(n, 2)
}

const getRange = (intDataPoints, txt) => {
  txt = (typeof txt === 'undefined') ? 'e' : txt
  let range = (txt + ',').repeat(intDataPoints).split(',')
  range = range.map((e, i) => e + (1 + i))
  range.length-- // remove last item
  return range
}

const getRandomInt = (min, max) => {
  return Math.floor(Math.random() * (max - min + 1)) + min
}

const sleep = async (ms) => {
  return new Promise(resolve => setTimeout(resolve, ms))
}

const copyJson = (jsonObject) => {
  return JSON.parse(JSON.stringify(jsonObject))
}

Math.radians = (degrees) => { // Converts from degrees to radians.
  return degrees * Math.PI / 180
}

Math.degrees = (radians) => { // Converts from radians to degrees.
  return radians * 180 / Math.PI
}

const calculateMidpoint = (from, to) => {
  const f = { lat: Math.radians(from.lat), lng: Math.radians(from.lng) }
  const t = { lat: Math.radians(to.lat), lng: Math.radians(to.lng) }
  const delta = Math.radians(to.lng - from.lng)
  const bX = Math.cos(t.lat) * Math.cos(delta)
  const bY = Math.cos(t.lat) * Math.sin(delta)
  const m = {}
  m.lat = Math.atan2(Math.sin(f.lat) + Math.sin(t.lat), Math.sqrt((Math.cos(f.lat) + bX) * (Math.cos(f.lat) + bX) + bY * bY))
  m.lng = f.lng + Math.atan2(bY, Math.cos(f.lat) + bX)
  m.lng = (m.lng + 3 * Math.PI) % (2 * Math.PI) - Math.PI // normalise to -180..+180°
  const middle = { lat: Math.degrees(m.lat), lng: Math.degrees(m.lng) }
  return middle
}

const calculateLatLng = (from, bearing, distance) => {
  // from: {lat: 123, lng: 123}, distance in meter
  const r = 6378137 // Radius of the Earth in meter
  const supportedBearings = { north: 0, northeast: 45, east: 90, southeast: 135, south: 180, southwest: 225, west: 270, northwest: 315 }
  const brng = (typeof bearing === 'string') ? supportedBearings[toLowerCase(bearing)] : bearing
  // convert to radians
  const f = { lat: Math.radians(from.lat), lng: Math.radians(from.lng) }
  const t = {}
  t.lat = Math.asin(Math.sin(f.lat) * Math.cos(distance / r) + Math.cos(f.lat) * Math.sin(distance / r) * Math.cos(brng))
  t.lng = f.lng + Math.atan2(Math.sin(brng) * Math.sin(distance / r) * Math.cos(f.lat), Math.cos(distance / r) - Math.sin(f.lat) * Math.sin(t.lat))
  // convert to degrees
  const to = { lat: Math.degrees(t.lat), lng: Math.degrees(t.lng) }
  return to
}

const calculateLatLngDistanceInMeter = (from, to) => {
  // from: {lat: 123, lng: 123}
  // to: {lat: 234, lng: 234}
  const r = 6378137 // Radius of the Earth
  const dLat = Math.radians(to.lat - from.lat)
  const dLng = Math.radians(to.lng - from.lng)
  const aaLat = Math.sin(dLat / 2) * Math.sin(dLat / 2)
  const abLat = Math.cos(Math.radians(from.lat)) * Math.cos(Math.radians(to.lat))
  const abLng = Math.sin(dLng / 2) * Math.sin(dLng / 2)
  const a = aaLat + abLat * abLng
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))
  const d = roundToTwo(r * c) // meter
  return d
}

const getLatLngArray = (from, to, numberOfLatLng) => {
  // find centre
  // const brng = calculateBearing(from, to)
  // addToLog('getLatLngArray - brng: ' + brng, true);
  const distance = calculateLatLngDistanceInMeter(from, to)
  // addToLog('getLatLngArray - distance: ' + distance, true);
  // let centre = calculateLatLng(from, brng, distance / 2);
  const centre = calculateMidpoint(from, to)
  // addToLog('getLatLngArray - centre: ' + JSON.stringify(centre), true);
  // const i = parseInt(Math.sqrt(numberOfLatLng), 10)
  // const j = numberOfLatLng / i
  // addToLog('getLatLngArray - i: ' + i + ', j: ' + j, true);
  const result = []
  let b = 0 // start bearing
  const rStart = distance / numberOfLatLng / 5 // start radius
  let r = rStart
  let v = centre
  for (let i = 1; i <= numberOfLatLng; i++) {
    // addToLog('getLatLngArray - i: ' + i + ', b: ' + b + ', r: ' + r, true);
    v = calculateLatLng(v, b, r)
    result.push(v)
    b += 45
    r += rStart
  }
  return result
}

const getVeEnvValue = (vecfg, veEnvKey, veEnvKeyValue, veEnvReturnKey, debug = debugData) => {
  // 'name', 've-cd-dev', 'awsAccountId'
  // 'index', 0, 'awsAccountId'
  // vecfg = {"envs":[{"name":"ve-prov","awsAccountId":"123456789012"},...]}
  if (hasProp(vecfg, 'cfg')) vecfg = vecfg.cfg
  const veEnvs = hasProp(vecfg, 'envs') ? vecfg.envs : vecfg
  let veEnv = []
  if (veEnvKey === 'index') {
    veEnv = veEnvs[veEnvKeyValue]
  } else {
    veEnv = veEnvs.filter(env => env[veEnvKey] === veEnvKeyValue)[0]
  }
  return hasProp(veEnv, veEnvReturnKey) ? veEnv[veEnvReturnKey] : null
}

const getVeEnvAwsAccountId = (vecfg, veEnvName, debug = debugData) => {
  return getVeEnvValue(vecfg, 'name', veEnvName, 'awsAccountId', debug)
}

const getVeEnvSatApiBaseURI = (vecfg, veEnvName, debug = debugData) => {
  return getVeEnvValue(vecfg, 'name', veEnvName, 'satApiBaseURI', debug)
}

const getVeEnvGqlClientURI = (vecfg, veEnvName, debug = debugData) => {
  return getVeEnvValue(vecfg, 'name', veEnvName, 'gqlClientURI', debug)
}

const getVeEnvUserPoolId = (vecfg, veEnvName, debug = debugData) => {
  return getVeEnvValue(vecfg, 'name', veEnvName, 'userPoolId', debug)
}

const getVeEnvIdentityPoolId = (vecfg, veEnvName, debug = debugData) => {
  return getVeEnvValue(vecfg, 'name', veEnvName, 'identityPoolId', debug)
}

const getVeEnvCognitoAppClientId = (vecfg, veEnvName, debug = debugData) => {
  return getVeEnvValue(vecfg, 'name', veEnvName, 'cognitoAppClientId', debug)
}

const getVeEnvCognitoAppWebDomain = (vecfg, veEnvName, debug = debugData) => {
  return getVeEnvValue(vecfg, 'name', veEnvName, 'cognitoAppWebDomain', debug)
}

const getVeEnvCognitoAuthenticatedLogins = (vecfg, veEnvName, debug = debugData) => {
  return getVeEnvValue(vecfg, 'name', veEnvName, 'cognitoAuthenticatedLogins', debug)
}

const getVeEnvIotEndpoint = (vecfg, veEnvName, debug = debugData) => {
  return getVeEnvValue(vecfg, 'name', veEnvName, 'iotEndpoint', debug)
}

const getVeEnvNames = (vecfg, debug = debugData) => {
  if (hasProp(vecfg, 'cfg')) vecfg = vecfg.cfg
  const veEnvs = hasProp(vecfg, 'envs') ? vecfg.envs : vecfg
  return veEnvs.map(env => env.name)
}

const getCognitoRedirectUri = (debug = debugData) => {
  const wl = window.location
  const uri = wl.protocol + '//' + wl.host + wl.pathname
  return uri
}

const useGMC = (vecfg, debug = debugData) => {
  if (hasProp(vecfg, 'cfg')) vecfg = vecfg.cfg
  return vecfg.veGMCEnvs.includes(getVeEnv(debug))
}

const getDataSource = (vecfg, debug = debugData) => {
  return useGMC(vecfg) ? 'gmc' : 'legacy'
}

const convertJsonToCsv = async (jsonContent, debug = debugData) => {
  let array = jsonContent // [{'a': 1, 'b': 2, 'c': 'xyz'}, ...]
  if (typeof array === 'string') {
    array = (array.startsWith('[')) ? array : '[' + array
    array = (array.endsWith(']')) ? array : array + ']'
    array = JSON.parse(array)
  }
  if (!Array.isArray(array)) array = [array]
  let csvContent = ''
  const keys = Object.keys(array[0])
  const header = keys.join(del)
  for (let i = 0; i < array.length; i++) {
    let line = ''
    for (const key of keys) {
      if (line !== '') line += ','
      line += array[i][key]
    }
    csvContent += line + newline
  }
  return header + newline + csvContent
}

const getUniqueArray = (arrayWithDuplicates) => {
  const uniqueArray = arrayWithDuplicates.reduce((previous, item) => {
    if (!previous.some(element => element === item)) {
      previous.push(item)
    }
    return previous
  }, [])
  return uniqueArray
}

const getUniqueListBy = (arr, key) => {
  return [...new Map(arr.map(item => [item[key], item])).values()]
}

export {
  addToLog,
  calcReadsBetweenTwoTS,
  calcSecondsBetweenTwoTS,
  copyJson,
  dateAdd,
  dateAddShortISO,
  dateAddShortISOLocal,
  dateToShortISO,
  decimalToTimezoneOffsetString,
  getSecondsSinceMidnight,
  decodeJwt,
  eraseCookie,
  evaluateLogicalAnd,
  evaluateLogicalExpression,
  evaluateLogicalOr,
  formatTimeStamp,
  getAccessToken,
  getCognitoRedirectUri,
  getDviAccessToken,
  getDviRefreshToken,
  setDviAccessToken,
  setDviRefreshToken,
  getDynamicPriceJson,
  getDynamicPriceProfile,
  getCurtailmentProfile,
  getCookie,
  getDataSource,
  getLongISOTimeStamp,
  getMicrogridTimeStamp,
  getPSetPoint,
  getCurtailmentPercentage,
  getPrice,
  getTimeStamp,
  getTimezoneFromContext,
  getTimezoneFromMicrogridId,
  getTimezoneOffset,
  getTimezoneOffsetInSeconds,
  getUniqueArray,
  getUniqueListBy,
  getVeEnv,
  getDatalakeBucketName,
  getVeEnvAwsAccountId,
  getVeEnvCognitoAppClientId,
  getVeEnvCognitoAppWebDomain,
  getVeEnvCognitoAuthenticatedLogins,
  getVeEnvGqlClientURI,
  getVeEnvIdentityPoolId,
  getVeEnvIotEndpoint,
  getVeEnvNames,
  getVeEnvSatApiBaseURI,
  getVeEnvUserPoolId,
  getVeEnvValue,
  hasProp,
  getLatLngArray,
  initDebug,
  initPageDvi,
  initVeEnv,
  initVeEnvDvi,
  isAccessTokenExpired,
  isApiExpression,
  isLogicalExpression,
  isMathjsExpression,
  isMoustacheExpression,
  isNumeric,
  isTokenExpired,
  isShortISO,
  jsDateToLocalISO,
  jsDateToLocalShortISO,
  longISOAddSeconds,
  longISOToShortISO,
  longISOToUnixTimestamp,
  migrateVeEnv,
  padTo,
  padToTwo,
  getRange,
  getRandomInt,
  parseBoolean,
  parseJwt,
  redirectToLogin,
  roundTo,
  roundToTwo,
  setCookie,
  setVeEnv,
  shortISOAddSeconds,
  shortISOToDate,
  shortISOToLongISO,
  shortISOToUnixTimestamp,
  shortISOUtcToLocal,
  sleep,
  toFirstUpperCase,
  toFirstLowerCase,
  toLongISO,
  toLowerCase,
  toProperCase,
  toUpperCase,
  unixTimestampToShortISO,
  useGMC,
  utcISOToJSDate,
  utcISOToLocalISO,
  convertJsonToCsv
}
